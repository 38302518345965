import React, { useState, useEffect } from "react";
import axios from "axios";
import { PRODUCTS_CRUD_API } from "../hooks/APIHooks";
import { CATEGORIES } from "../components/GlobalData";
import { IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(PRODUCTS_CRUD_API);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleAddOrUpdate = async (event) => {
    event.preventDefault();
    try {
      const data = { category, name, description, price };
      if (editId) {
        const response = await axios.put(`${PRODUCTS_CRUD_API}?id=${editId}`, data);
        setMessage(response.data.message);
      } else {
        const response = await axios.post(PRODUCTS_CRUD_API, data);
        setMessage(response.data.message);
      }
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error("Error saving product:", error);
      setMessage("Error saving product");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.delete(`${PRODUCTS_CRUD_API}?id=${id}`);
        setMessage("Product deleted successfully");
        fetchProducts();
      } catch (error) {
        console.error("Error deleting product:", error);
        setMessage("Error deleting product");
      }
    }
  };

  const handleEdit = (product) => {
    setCategory(product.category);
    setName(product.name);
    setDescription(product.description);
    setPrice(product.price);
    setEditId(product.id);
  };

  const resetForm = () => {
    setCategory("");
    setName("");
    setDescription("");
    setPrice("");
    setEditId(null);
    setMessage("");
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);

    const sortedProducts = [...products].sort((a, b) => {
      if (direction === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setProducts(sortedProducts);
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return sortDirection === "asc" ? faSortUp : faSortDown;
    } else {
      return faSort;
    }
  };

  const filteredProducts = filterCategory
    ? products.filter((product) => product.category === filterCategory)
    : products;

  return (
    <div className="p-4 bg-gray-50 min-h-screen mb-10">
      <h1 className="text-3xl font-bold text-gray-800">Products</h1>
      <p className="text-sm text-black mb-4 flex items-center w-[8v]">
        Adding Products in this panel will affect throughout your system.
      </p>

      {message && (
        <div className="mb-6 p-4 text-white bg-blue-600 rounded-md shadow-md">
          {message}
        </div>
      )}

      <form
        onSubmit={handleAddOrUpdate}
        className="bg-white p-6 rounded-lg shadow-md mb-8"
      >
        <h2 className="text-xl font-semibold mb-4">
          {editId ? "Update Product" : "Add New Product"}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <input
              type="text"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              required
            />
          </div>

          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              placeholder="This is be showed in invoice."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="price"
              className="block text-sm font-medium text-gray-700"
            >
              Price
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              step="0.01"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
        >
          {editId ? "Update Product" : "Add Product"}
        </button>
      </form>

      {/* Category Filter */}
      <div className="mb-6">
        <label
          htmlFor="filterCategory"
          className="block text-sm font-medium text-gray-700"
        >
          Filter by Category
        </label>
        <select
          id="filterCategory"
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        >
          <option value="">All Categories</option>
          {CATEGORIES.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100">
              <th
                className="p-2 border border-gray-300 text-left cursor-pointer"
                onClick={() => handleSort("category")}
              >
                Category <FontAwesomeIcon icon={getSortIcon("category")} />
              </th>
              <th
                className="p-2 border border-gray-300 text-left cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Name <FontAwesomeIcon icon={getSortIcon("name")} />
              </th>
              <th
                className="p-2 border border-gray-300 text-left cursor-pointer"
                onClick={() => handleSort("price")}
              >
                Price <FontAwesomeIcon icon={getSortIcon("price")} />
              </th>
              <th className="p-2 border border-gray-300 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.id} className="hover:bg-gray-100">
                <td className="p-2 border border-gray-300 text-[12px]">{product.category}</td>
                <td className="p-2 border border-gray-300 text-[12px]">{product.name}</td>
                <td className="p-2 border border-gray-300 text-[12px]">{product.price} ₹</td>
                <td className="p-2 border border-gray-300">
                  <button
                    onClick={() => handleEdit(product)}
                    className="mr-2 bg-blue-500 text-white rounded-md p-1 m-1 hover:bg-blue-600 transition duration-200 flex items-center justify-center"
                  >
                    <IoPencilSharp />
                  </button>
                  <button
                    onClick={() => handleDelete(product.id)}
                    className="bg-red-500 text-white rounded-md p-1 m-1 hover:bg-red-600 transition duration-200 flex items-center justify-center"
                  >
                    <IoTrashSharp />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsPage;
