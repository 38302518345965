import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { CUSTOMERS_CRUD_API } from "../hooks/APIHooks";
import { IoPencilSharp, IoTrashSharp } from "react-icons/io5";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

const ClientsPage = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [sex, setSex] = useState("Male");
  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (phoneFilter) {
      setFilteredCustomers(
        customers.filter((customer) => customer.phone.includes(phoneFilter))
      );
    } else {
      setFilteredCustomers(customers);
    }
  }, [phoneFilter, customers]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(CUSTOMERS_CRUD_API);
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleAddOrUpdate = async (event) => {
    event.preventDefault();
    try {
      const data = { name, email, phone, address, sex };
      if (editId) {
        const response = await axios.put(
          `${CUSTOMERS_CRUD_API}?id=${editId}`,
          data
        );
        if (response.data.message === "Phone number or email already exists") {
          setMessage("Phone number or email already exists");
          return;
        }
        setMessage("Customer updated successfully");
      } else {
        const response = await axios.post(CUSTOMERS_CRUD_API, data);
        if (response.data.message === "Phone number or email already exists") {
          setMessage("Phone number or email already exists");
          return;
        }
        setMessage("Customer added successfully");
      }
      fetchCustomers();
      resetForm();
    } catch (error) {
      console.error("Error saving customer:", error);
      setMessage("Error saving customer");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await axios.delete(`${CUSTOMERS_CRUD_API}?id=${id}`);
        setMessage("Customer deleted successfully");
        fetchCustomers();
      } catch (error) {
        console.error("Error deleting customer:", error);
        setMessage("Error deleting customer");
      }
    }
  };

  const handleEdit = (customer) => {
    setName(customer.name);
    setEmail(customer.email);
    setPhone(customer.phone);
    setAddress(customer.address);
    setSex(customer.sex);
    setEditId(customer.id);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setSex("Male");
    setEditId(null);
    setMessage("");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="border-b px-2 py-4">
            <button
              onClick={() => handleEdit(row.original)}
              className="bg-violet-500 text-white rounded-md p-1 m-1 hover:bg-violet-600 transition duration-200 flex items-center justify-center"
            >
              <IoPencilSharp className="text-[18px]" />
            </button>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="bg-red-500 text-white rounded-md p-1 m-1 hover:bg-red-600 transition duration-200 flex items-center justify-center"
            >
              <IoTrashSharp className="text-[18px]" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredCustomers,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="p-4 mx-auto mb-10">
      <h1 className="text-3xl font-bold text-center">Our Clients</h1>
      <p className="text-sm text-black mb-4">
        Adding Clients in this panel will affect throughout your system.
      </p>
      {message && (
        <div className="mb-6 p-4 text-white bg-violet-500 rounded-md">
          {message}
        </div>
      )}

      <form
        onSubmit={handleAddOrUpdate}
        className="mb-8 bg-white p-6 rounded-md shadow-md"
      >
        <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-violet-500 focus:border-violet-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-violet-500 focus:border-violet-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone
            </label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-violet-500 focus:border-violet-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-violet-500 focus:border-violet-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="sex"
              className="block text-sm font-medium text-gray-700"
            >
              Sex
            </label>
            <select
              id="sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-violet-500 focus:border-violet-500"
              required
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 w-full py-2 bg-violet-600 text-white rounded-md hover:bg-violet-700 transition duration-200"
        >
          {editId ? "Update Customer" : "Add Customer"}
        </button>
      </form>

      <input
        type="text"
        placeholder="Filter by phone"
        value={phoneFilter}
        onChange={(e) => setPhoneFilter(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded-md"
      />

      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full border-collapse border border-gray-300"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-gray-100"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="p-2 border border-gray-300"
                  >
                    <div className="flex items-center">
                      {column.render("Header")}
                      <span className="ml-2">
                        <FontAwesomeIcon
                          icon={
                            column.isSorted
                              ? column.isSortedDesc
                                ? faSortDown
                                : faSortUp
                              : faSort
                          }
                        />
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border border-gray-300">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 border border-gray-300"
                    >
                      <div className="flex flex-col md:flex-row">
                        <span className="font-semibold md:hidden">
                          {headerGroups[0].headers
                            .find((h) => h.id === cell.column.id)
                            .render("Header")}
                        </span>
                        {cell.render("Cell")}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 space-y-2">
          <div className="text-sm text-center text-gray-600">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="bg-gray-300 text-gray-700 p-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Previous
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="bg-gray-300 text-gray-700 p-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Next
            </button>
          </div>

          <div className="text-sm text-gray-600 text-center justify-center">
            Go to page:{" "}
            <input
              type="number"
              min="1"
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="w-16 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
        </div>
    </div>
  );
};

export default ClientsPage;
