import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  IoPeopleSharp,
  IoReceiptSharp,
  IoWalletSharp,
  IoTrendingUpSharp,
  IoArrowForward,
} from "react-icons/io5";
import { FcMoneyTransfer } from "react-icons/fc";
import {
  DAILYSPEND_CRUD_API,
  INVOICE_CRUD_API,
  CUSTOMERS_CRUD_API,
} from "../hooks/APIHooks";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  IoPrint,
  IoCart,
  IoPerson,
  IoSettings,
  IoWallet,
} from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { IoBarChartOutline } from "react-icons/io5";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import LogoLoader from "../components/Loader";

const CombinedDashboardAnalysis = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/");
  };
  const [dailySpend, setDailySpend] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [monthlyData, setMonthlyData] = useState([]);

  const months = [
    { value: "", label: "All Months" }, // Add this entry
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from({ length: 10 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dailySpendData } = await axios.get(DAILYSPEND_CRUD_API);
        setDailySpend(dailySpendData);

        const { data: invoicesData } = await axios.get(INVOICE_CRUD_API);
        setInvoices(invoicesData);

        const { data: customersData } = await axios.get(CUSTOMERS_CRUD_API);
        setTotalClients(customersData.length);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed in JavaScript

    const selectedYearValid = selectedYear
      ? parseInt(selectedYear)
      : currentYear;
    const selectedMonthValid =
      selectedMonth === ""
        ? null
        : selectedMonth
        ? parseInt(selectedMonth)
        : currentMonth;

    const filterByMonthAndYear = (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return false;
      }

      const month = date.getMonth() + 1; // Months are 0-indexed in JavaScript
      const year = date.getFullYear();
      return (
        (selectedMonthValid === null || month === selectedMonthValid) &&
        year === selectedYearValid
      );
    };

    const filteredSpend = dailySpend.filter((item) =>
      filterByMonthAndYear(item.created_at)
    );
    const filteredInvoices = invoices.filter((item) =>
      filterByMonthAndYear(item.invoice_date)
    );

    const totalSpendAmount = filteredSpend.reduce(
      (acc, item) => acc + parseFloat(item.daily_spend || 0),
      0
    );
    const totalReceivedAmount = filteredInvoices.reduce(
      (acc, item) => {
        if (item.status === "Paid") {
          return acc + parseFloat(item.total_amount || 0);
        }
        return acc;
      },
      0
    );
    

    setTotalSpend(totalSpendAmount);
    setTotalReceived(totalReceivedAmount);
    setTotalBalance(totalReceivedAmount - totalSpendAmount);

    // Prepare data for chart
    const monthlyProfits = [];
    for (let i = 1; i <= 12; i++) {
      const monthSpend = filteredSpend.filter(
        (item) => new Date(item.created_at).getMonth() + 1 === i
      );
      const monthInvoices = filteredInvoices.filter(
        (item) => new Date(item.invoice_date).getMonth() + 1 === i
      );

      const monthSpendAmount = monthSpend.reduce(
        (acc, item) => acc + parseFloat(item.daily_spend || 0),
        0
      );
      const monthReceivedAmount = monthInvoices.reduce(
        (acc, item) => acc + parseFloat(item.total_amount || 0),
        0
      );

      monthlyProfits.push({
        month: i,
        profit: monthReceivedAmount - monthSpendAmount,
      });
    }
    setMonthlyData(monthlyProfits);
  }, [dailySpend, invoices, selectedMonth, selectedYear]);

  if (loading) {
    return <LogoLoader/>;
  }

  if (error) {
    return <div className="text-center text-red-500 text-[18px]">{error}</div>;
  }

  return (
    <div className="p-4 mb-6">
      <div className="mb-8">
        <h1 className="text-4xl text-gray-800 font-bold">Dashboard</h1>
        <p className="text-sm text-gray-600 mt-2 mb-4 flex items-center">
          Welcome, please select your service. For more information, contact the
          developer for further assistance.
        </p>

        <div className="grid grid-cols-2 gap-1">
          <div>
            <label>Month:</label>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="border rounded p-2 w-full"
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Year:</label>
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="border rounded p-2 w-full"
            >
              <option value="">All Years</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div className="grid grid-row-2 shadow-md rounded-md ">
          <div className="bg-violet-100 flex flex-col p-2">
            <Link to="/dailyspend">
              <h2 className="text-[12px] font-semibold flex">
                <IoWalletSharp className="text-red-500 text-[16px] mr-1" />
                Total Spend{" "}
                <IoArrowForward className="text-black text-[14px] ml-2 my-auto " />
              </h2>
              <p className="text-[14px] font-bold text-red-600">
                ₹ {totalSpend.toFixed(2)}
              </p>
              <p className="text-[10px] text-gray-600 flex ">
                Amount you have spent on for your business.
              </p>
            </Link>

            <Link to="/grossprofit">
              <h2 className="text-[12px] font-semibold flex mt-2">
                {" "}
                <IoTrendingUpSharp className="text-green-500 text-[16px] mr-1" />{" "}
                Gross Profit{" "}
                <IoArrowForward className="text-black text-[14px] ml-2 my-auto " />
              </h2>
              <p className="text-[14px] font-bold text-green-600">
                ₹ {totalReceived.toFixed(2)}
              </p>
              <p className="text-[10px] text-gray-600 flex">
                Your total billing amount via invoices.
              </p>
            </Link>
          </div>
        </div>

        <div className="grid grid-row-2 shadow-md rounded-md ">
          <div className="bg-violet-100 flex flex-col p-2">
            <h2 className="text-[12px] font-semibold flex">
              <IoPeopleSharp className="text-blue-500 text-[16px]  mr-1" />{" "}
              Total Clients
            </h2>
            <p className="text-[14px] font-bold text-blue-600">
              {totalClients}
            </p>
            <p className="text-[10px] text-gray-600 flex ">
              Clients Entered in this dashboard.
            </p>

            <h2 className="text-[12px] font-semibold flex mt-2">
              {" "}
              <IoReceiptSharp className="text-yellow-500 text-[16px] mr-1" />{" "}
              Total Invoices
            </h2>
            <p className="text-[14px] font-bold text-yellow-600">
              {invoices.length}
            </p>
            <p className="text-[10px] text-gray-600 flex">
              Number of invoices created in this dashboard.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-md mt-6  flex flex-col items-center">
        <h2 className="text-[18px] font-semibold flex">
          {" "}
          <FcMoneyTransfer className="text-blue-500 text-2xl mr-1" />
          Net Profit
        </h2>
        <p className="text-[18px] font-bold text-green-600">
          ₹{totalBalance.toFixed(2)}
        </p>
        <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
          Gross Profit - Total Spend = Net Profit / Final Profit.
        </p>
      </div>

      <div className=" mt-6">
        <h2 className="flex pt-5 mb-2">
          <IoBarChartOutline className="text-blue-500 text-xl mr-1" />
          Monthly NetProfit
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart className="" data={monthlyData}>
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis
              dataKey="month"
              tickFormatter={(month) =>
                new Date(2024, month - 1).toLocaleString("default", {
                  month: "short",
                })
              }
              angle={-45}
              textAnchor="end"
              interval={0}
              tickMargin={0} // No extra margin
              style={{ margin: 0, padding: 0 }} // Ensure no extra space
            />
            <YAxis style={{ margin: 0, padding: 0 }} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="profit" stroke="#000000" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="boarder my-5 border-black/50 border-[0.5px]" />

      <div className="bg-white text-center justify-center items-center ">
        <h2 className="text-[14px] font-bold my-3">Quick Create</h2>{" "}
        <div className="grid grid-cols-4 gap-4">
          <div>
            <Link
              to="/invoices"
              className="flex flex-col items-center justify-center bg-yellow-500 text-gray-800 p-4 rounded-lg shadow-lg hover:bg-yellow-600 hover:text-white transition duration-300"
            >
              <IoReceiptSharp className="text-3xl  text-white" />
            </Link>
            <h3 className="text-[14px]  font-bold mt-2">Invoices</h3>
            <p className="text-[8px] text-center">Create & Manage your Bills</p>
          </div>

          <div>
            <Link
              to="/clients"
              className="flex flex-col items-center justify-center bg-blue-500 text-white p-4 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
            >
              <IoPerson className="text-3xl  " />
            </Link>
            <h3 className="text-[14px]  font-bold mt-2">Clients</h3>
            <p className="text-[8px] text-center">Create & Manage your Bills</p>
          </div>

          <div>
            <Link
              to="/products"
              className="flex flex-col items-center justify-center bg-teal-500 text-white p-4 rounded-lg shadow-lg hover:bg-teal-600 hover:text-white transition duration-300"
            >
              <IoCart className="text-3xl  " />
            </Link>
            <h3 className="text-[14px]  font-bold mt-2">Products</h3>
            <p className="text-[8px] text-center">Create & Manage your Bills</p>
          </div>

          <div>
            <Link
              to="/dailyspend"
              className="flex flex-col items-center justify-center bg-red-500 text-white p-4 rounded-lg shadow-lg hover:bg-red-600 hover:text-white transition duration-300"
            >
              <IoWallet className="text-3xl  " />
            </Link>
            <h3 className="text-[14px]  font-bold mt-2">Daily Spend</h3>
            <p className="text-[8px] text-center">Create & Manage your Bills</p>
          </div>
        </div>
      </div>

      <div className="boarder my-5 border-black/50 border-[0.5px]" />

      <div className="my-6">
        {/* Header Section */}

        {/* Services Section */}
        <div className="text-center mb-6">
          <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
            Services
          </h2>
          <p className="text-gray-500 text-sm mt-1">
            All the services you need to manage your business.
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
          <Link
            to="/invoices"
            className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
          >
            <IoPrint className="text-3xl mb-2 " />
            <h3 className="text-[16px]  font-bold">Invoices</h3>
            <p className="text-[10px] text-center">
              Create & Manage your Bills
            </p>
          </Link>
        </div>

        <div className="border-y-[0.5px] border-gray-300 border my-2" />
        {/* Business Section */}
        <div className="text-center mb-6">
          <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
            Business
          </h2>
          <p className="text-gray-500 text-sm mt-1">
            Manage your business with an overview.
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
          <Link
            to="/dailyspend"
            className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
          >
            <IoWallet className="text-3xl mb-2" />
            <h3 className="text-[16px] font-bold">Daily Spend</h3>
            <p className="text-[10px] text-center">
              Track your daily expenditures
            </p>
          </Link>

          <Link
            to="/analysis"
            className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
          >
            <IoBarChartOutline className="text-3xl mb-2" />
            <h3 className="text-[16px]  font-bold">Reports</h3>
            <p className="text-[10px] text-center">
              Analyze your business performance
            </p>
          </Link>
        </div>

        <div className="border-y-[0.5px] border-gray-300 border my-2" />

        {/* App Settings Section */}
        <div className="text-center mb-6">
          <h2 className="text-[20px] font-semibold tracking-tight text-gray-800">
            App Settings
          </h2>
          <p className="text-gray-500 text-sm mt-1">
            Customize your app settings.
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Link
            to="/settings"
            className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
          >
            <IoSettings className="text-3xl mb-2" />
            <h3 className="text-[16px]  font-bold">Settings</h3>
            <p className="text-[10px] text-center">
              Configure your business details
            </p>
          </Link>
          <button
            onClick={handleLogout}
            className="flex flex-col items-center justify-center bg-red-500 text-white p-6 rounded-lg shadow-lg hover:bg-red-600 transition duration-300"
          >
            <IoMdLogOut className="text-3xl mb-2" />
            <h3 className="text-[16px]  font-bold">Logout</h3>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CombinedDashboardAnalysis;
