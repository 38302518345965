import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoPeopleSharp, IoReceiptSharp, IoWalletSharp, IoTrendingUpSharp, IoScaleSharp } from 'react-icons/io5';
import { DAILYSPEND_CRUD_API, INVOICE_CRUD_API, CUSTOMERS_CRUD_API } from '../hooks/APIHooks';
import LogoLoader from '../components/Loader'

const CombinedDashboardAnalysis = () => {
  const [dailySpend, setDailySpend] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  const years = Array.from({ length: 10 }, (_, i) => (new Date().getFullYear() - i).toString());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dailySpendData } = await axios.get(DAILYSPEND_CRUD_API);
        setDailySpend(dailySpendData);

        const { data: invoicesData } = await axios.get(INVOICE_CRUD_API);
        setInvoices(invoicesData);

        const { data: customersData } = await axios.get(CUSTOMERS_CRUD_API);
        setTotalClients(customersData.length);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterByMonthAndYear = (dateString, format) => {
      let date = new Date();

      // Parse the date based on format
      if (format === 'YYYY-MM-DD') {
        date = new Date(dateString);
      } else if (format === 'DD-MM-YYYY HH:MM:SS') {
        const [day, month, year] = dateString.split(' ')[0].split('-');
        date = new Date(`${year}-${month}-${day}`);
      } else if (format === 'YYYY-MM-DD HH:MM') {
        date = new Date(dateString.split(' ')[0]);
      }

      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();

      return (!selectedMonth || month === selectedMonth) && (!selectedYear || year === selectedYear);
    };

    const filteredSpend = dailySpend.filter(item =>
      filterByMonthAndYear(item.created_at, 'YYYY-MM-DD HH:MM')
    );

    const filteredInvoices = invoices.filter(item =>
      filterByMonthAndYear(item.invoice_date, 'YYYY-MM-DD') ||
      filterByMonthAndYear(item.created_at, 'DD-MM-YYYY HH:MM:SS')
    );

    const totalSpendAmount = filteredSpend.reduce((acc, item) => acc + parseFloat(item.daily_spend), 0);
    setTotalSpend(totalSpendAmount);

    const totalReceivedAmount = filteredInvoices.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
    setTotalReceived(totalReceivedAmount);

    setTotalBalance(totalReceivedAmount - totalSpendAmount);
  }, [dailySpend, invoices, selectedMonth, selectedYear]);

  if (loading) {
    return <LogoLoader />;
  }

  if (error) {
    return <div className="text-center text-red-500 text-[18px]">{error}</div>;
  }

  return (
    <div className="p-6 mb-6">
      <div className="grid grid-cols-2 gap-8 mb-8">
        <div>
          <label>Month:</label>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="border rounded p-2 w-full"
          >
            <option value="">All Months</option>
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Year:</label>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="border rounded p-2 w-full"
          >
            <option value="">All Years</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 mb-8">
        <div className="bg-white shadow-md rounded-md p-4 flex flex-col items-center">
          <IoPeopleSharp className="text-blue-500 mb-2 text-4xl" />
          <h2 className="text-[18px] font-bold text-center mb-2">Total Clients</h2>
          <div className="text-[18px] font-bold text-center">{totalClients}</div>
          <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
            Clients Entered in this dashboard.
          </p>
        </div>
        <div className="bg-white shadow-md rounded-md p-4 flex flex-col items-center">
          <IoReceiptSharp className="text-green-500 mb-2 text-4xl" />
          <h2 className="text-[18px] font-bold text-center mb-2">Total Invoices</h2>
          <div className="text-[18px] font-bold text-center">{invoices.length}</div>
          <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
            Number of invoices created in this dashboard.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white shadow-md rounded-md p-4 flex flex-col items-center">
          <IoWalletSharp className="text-red-500 mb-2 text-4xl" />
          <h2 className="text-[18px] font-semibold mb-2">Total Spend</h2>
          <p className="text-[18px] font-bold text-red-600">₹{totalSpend.toFixed(2)}</p>
          <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
            Amount you have spent on for your business.
          </p>
        </div>
        <div className="bg-white shadow-md rounded-md p-4 flex flex-col items-center">
          <IoTrendingUpSharp className="text-green-500 mb-2 text-4xl" />
          <h2 className="text-[18px] font-semibold mb-2">Gross Profit</h2>
          <p className="text-[18px] font-bold text-green-600">₹{totalReceived.toFixed(2)}</p>
          <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
            Your total billing amount via invoices.
          </p>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-md p-4 flex flex-col items-center">
        <IoScaleSharp className="text-blue-500 mb-2 text-4xl" />
        <h2 className="text-[18px] font-semibold mb-2">Net Profit</h2>
        <p className="text-[18px] font-bold text-blue-600">₹{totalBalance.toFixed(2)}</p>
        <p className="text-[10px] text-gray-600 mt-2 mb-4 flex text-center">
          Gross Profit - Total Spend = Net Profit / Final Profit.
        </p>
      </div>
    </div>
  );
};

export default CombinedDashboardAnalysis;
