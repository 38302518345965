// config.js

const BASE_URL = (() => {
    const hostname = window.location.hostname;
  
    if (hostname === "invoice.cyberspacedigital.in") {
      return "https://invoice.cyberspacedigital.in/apis/";
    } else if (hostname === "invoice.ilahe.in") {
      return "https://invoice.ilahe.in/apis/";
    } else if (hostname === "juiceinvoice.cyberspacedigital.in") {
      return "https://juiceinvoice.cyberspacedigital.in/apis/";
    } else {
      return "https://cyberspacedigital.in/"; // Fallback if no match
    }
  })();
  
  // Define the API endpoints dynamically
  const USER_CRUD_API = `${BASE_URL}CRUD.php`;
  const INVOICE_CRUD_API = `${BASE_URL}invoice_CRUD.php`;
  const EMAIL_API = `${BASE_URL}invoice/Email_invoice.php`;
  const CUSTOMERS_CRUD_API = `${BASE_URL}customers_CRUD.php`;
  const PRODUCTS_CRUD_API = `${BASE_URL}products_CRUD.php`;
  const DAILYSPEND_CRUD_API = `${BASE_URL}DailySpend_CRUD.php`;
  const SETTINGS_CRUD_API = `${BASE_URL}Settings_CRUD.php`;
  
  // Export all API endpoints
  export { 
    USER_CRUD_API, 
    INVOICE_CRUD_API, 
    EMAIL_API, 
    CUSTOMERS_CRUD_API, 
    PRODUCTS_CRUD_API, 
    DAILYSPEND_CRUD_API, 
    SETTINGS_CRUD_API 
  };
  