import Companylogo from "../assets/Companylogo.png";

const CATEGORIES = [
  "Grooming",
  "Skincare services",
  "Nail services",
  "Makeup services",
  "Massage services",
];

const GlobalData = {
  company: {
    companyName: "Juice Salon-Visakhapatnam",
    companyPhone: "+91 8096598155",
    companyPhone2: "+91 7893350700",
    companyEmail: "juicesalonvizag@gmail.com",
    companyAddress:
      "Juice Salon, Gangapur Layout, Siripuram, Visakhapatnam, Andhra Pradesh 530003, India",
    GST: "GSTIN 37FYYPS8275L2ZT",
    companyWhatsapp: "https://api.whatsapp.com/send?phone=+918096598155",
    companyGoogleMaps: "https://g.page/r/Cbdbq2SKZJyVEAI/review",
  },
};
export { GlobalData, CATEGORIES, Companylogo };
