import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdLogIn } from "react-icons/io"; // Optional: Import an icon for the button
import { IoCall } from "react-icons/io5"; // Import icons
import { USER_CRUD_API } from "./hooks/APIHooks";
import { Companylogo } from "./components/GlobalData";

const LoginPage = () => {
  const [phonenumber, setPhonenumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(USER_CRUD_API);
      const users = response.data;

      // Check for a matching user
      const user = users.find(
        (user) => user.phonenumber === phonenumber && user.password === password
      );

      if (user) {
        // Save login state in localStorage
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("phonenumber", phonenumber); // Optional

        navigate("/dashboard");
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Error logging in. Please try again.");
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen "
      style={{
        backgroundImage: `url("${Companylogo}")`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="max-w-md w-full bg-white/50 p-8 shadow-lg rounded-lg border border-gray-300">
        <h2 className="text-3xl font-bold text-center text-gray-800">Login</h2>
        <h2 className="text-[14px] mb-6 text-center text-gray-800">
          Enter your admin login credentials
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label
              htmlFor="phonenumber"
              className="block text-sm font-medium text-black"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="phonenumber"
              value={phonenumber}
              onChange={(e) => setPhonenumber(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-black"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              required
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm mb-4 text-center">{error}</p>
          )}

          <button
            type="submit"
            className="w-full bg-violet-600 text-white py-3 px-4 rounded-md shadow-md hover:bg-violet-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 flex items-center justify-center"
          >
            <IoMdLogIn className="mr-2 text-xl" /> {/* Optional icon */}
            Login
          </button>
        </form>

        <div className="pt-4 text-center">
          <a
            href="tel:8143407758"
            className="flex justify-center bg-black px-4 py-2 rounded-md text-[10px] items-center text-white hover:text-yellow-500 ml-2"
          >
            <IoCall className="text-[20px] mr-1" />
            Forgot your password? Call Now
          </a>

          <footer className="text-[10px] mt-1 text-center text-black bottom-0 ">
            <p>
              &copy; {new Date().getFullYear()} CyberSpaceDigital. All rights
              reserved.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
