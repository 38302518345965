import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import { Companylogo } from "./GlobalData";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    navigate("/");
  };

  // Hide Navbar on the login page
  const showNavbar = location.pathname !== "/";

  return (
    showNavbar && (
      <nav className="bg-black z-[100] p-4 flex justify-between items-center w-screen top-0 sticky">
        <div className="text-white text-xl font-bold">
          <a href="/dashboard" className="flex justify-center my-auto">
            {" "}
            <img
              src={Companylogo}
              alt="csdlogo"
              className="cursor-pointer w-8 h-auto mr-2 "
            />
            CRM Geni
          </a>
        </div>

        <div
          onClick={handleLogout}
          className="hover:cursor-pointer  text-white "
        >
          <IoMdLogOut className="text-3xl" />
        </div>
      </nav>
    )
  );
};

export default Navbar;
