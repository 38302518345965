import React  from "react";
import { Link } from "react-router-dom";
import { IoCart, IoPerson, IoBusinessSharp } from "react-icons/io5";

const Settings = () => {
 

  return (
    <div className="container p-8 max-w-4xl mx-auto mb-5">
      <h1 className="text-2xl font-bold text-center">Settings</h1>
      <p className="text-[12px] mb-4 text-center">
        Please make sure to add all your company details, this details will be
        effected throught out your system.
      </p>

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
        <Link
          to="/products"
          className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
        >
          <IoCart className="text-3xl mb-2" />
          <h3 className="text-[16px]  font-bold">Products</h3>
          <p className="text-[10px] text-center">Add your products/services</p>
        </Link>
        <Link
          to="/clients"
          className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
        >
          <IoPerson className="text-3xl mb-2" />
          <h3 className="text-[16px]  font-bold">Clients</h3>
          <p className="text-[10px] text-center">Add your customers</p>
        </Link>

        <Link
          to="/companydetails"
          className="flex flex-col items-center justify-center bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:bg-blue-600 hover:text-white transition duration-300"
        >
          <IoBusinessSharp className="text-3xl mb-2" />
          <h3 className="text-[16px]  font-bold">Company Details</h3>
          <p className="text-[10px] text-center">Add all your company details.</p>
        </Link>
      </div>

    </div>
  );
};

export default Settings;
